import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { HiPlusSm } from 'react-icons/hi';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { Modal } from '../../components/Modal';
import { CreateSubscription, UpdateSubscription } from './Modals';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import * as developerApi from '../../../api/developer';
import * as developerAction from '../../../actions/developer';
import { handleRequestErrors } from '../../../utils';
import { ReducerStates } from '../../../typings';
import { ConditionalElement } from '../../components/ConditionalElement';
import { CopiableText } from '../../components/CopiableText';
import { Security2faDialog } from '../../components/2FADialog';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Menu, { Item as MenuItem } from 'rc-menu';
import RcDropdown from 'rc-dropdown';
import Gap from '../../components/Gap';
import '../settings.scss';
import './styles.scss';

export const subscriptionEventsOptions: Array<{ value: string; label: string }> = [
  {
    value: 'CRYPTO_DEPOSIT',
    label: 'Crypto Deposits',
  },
  {
    value: 'CRYPTO_WITHDRAWAL',
    label: 'Crypto Withdrawals',
  },
  {
    value: 'FIAT_DEPOSIT',
    label: 'Fiat Deposits',
  },
  {
    value: 'FIAT_WITHDRAWAL',
    label: 'Fiat Withdrawals',
  },
  {
    value: 'CARD_CREATED',
    label: 'Card Created',
  },
  {
    value: 'CARD_FUNDING',
    label: 'Card Funding',
  },
  {
    value: 'CARD_WITHDRAWAL',
    label: 'Card Withdrawals',
  },
  {
    value: 'CARD_CHARGED',
    label: 'Card Charged',
  },
  {
    value: 'CARD_DECLINED',
    label: 'Card Declined',
  },
  {
    value: 'CARD_REFUNDED',
    label: 'Card Refunded',
  },
  {
    value: 'CARD_REVERSAL',
    label: 'Card Reversal',
  },
  {
    value: 'CARD_TERMINATED',
    label: 'Card Terminated',
  },
  {
    value: 'CARD_FROZEN',
    label: 'Card Frozen',
  },
  {
    value: 'CARD_UNFROZEN',
    label: 'Card Unfrozen',
  },
  {
    value: 'ADDRESS_CREATED',
    label: 'Address generated',
  },
  {
    value: 'CARD_CROSS_BORDER_AUTH_CHARGE',
    label: 'Card cross-border charge event',
  },
  {
    value: 'CARD_DECLINE_CHARGE_DOM',
    label: 'Card domestic decline charge event',
  },
  {
    value: 'CARD_DECLINE_CHARGE_INTL',
    label: 'Card international decline charge event',
  },
  {
    value: 'CROSS_BORDER_AUTH_CHARGE',
    label: 'Wallet cross-border charge event',
  },
  {
    value: 'DECLINE_DOM_AUTH_CHARGE',
    label: 'Wallet domestic decline charge event',
  },
  {
    value: 'DECLINE_INTL_AUTH_CHARGE',
    label: 'Wallet international decline charge event',
  },
];

export const DeveloperWebhooks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { developer } = useSelector((state: ReducerStates) => state);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    developerApi
      .getWebhookSubscriptions()
      .then((data) => dispatch(developerAction.setWebhookSubscriptions(data.subscriptions)))
      .catch((err) => handleRequestErrors(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Webhook Subscriptions</h6>
                      </div>

                      <div className="col-auto">
                        <button
                          className="btn btn-primary  btn-float-mobile"
                          onClick={() =>
                            Modal.Open(<CreateSubscription />, {
                              customClass: {
                                container: 'modal-mobile-fullscreen',
                                popup: 'max-w-400x',
                              },
                            })
                          }
                        >
                          <span className="text hidden-768px">Add Subscription</span>
                          <span className="icon visible-768px">
                            <HiPlusSm />
                          </span>
                        </button>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="table-basic table-dev-keys">
                      <table>
                        <thead className="hidden-768px">
                          <tr>
                            <th>URL</th>
                            <th>Events</th>
                            <th>Date Created</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {developer.subscriptions.map((sub) => (
                            <tr key={sub.id}>
                              <td>
                                <div style={{ whiteSpace: 'normal', maxWidth: '100%' }}>
                                  <CopiableText text={sub.url} />
                                </div>
                                <div className="visible-768px" style={{ whiteSpace: 'normal', maxWidth: '100%' }}>
                                  {sub.events.map((evt) => subscriptionEventsOptions.find((opt) => opt.value === evt)?.label).join(', ')}
                                </div>
                                <small className="text-muted visible-768px">
                                  {moment(new Date(sub.createdAt)).format('MMM DD, yyyy hh:mm a')}
                                </small>
                              </td>
                              <td className="hidden-768px">
                                <div style={{ whiteSpace: 'normal', maxWidth: '200px' }}>
                                  {sub.events.map((evt) => subscriptionEventsOptions.find((opt) => opt.value === evt)?.label).join(', ')}
                                </div>
                              </td>
                              <td className="hidden-768px">{moment(sub.createdAt).format('MMM DD, yyyy hh:mm a')}</td>
                              <td>
                                <RcDropdown
                                  trigger={['click']}
                                  overlay={
                                    <Menu>
                                      <MenuItem
                                        className=""
                                        key={`webhook-sub-${sub.id}-edit`}
                                        onClick={() =>
                                          Modal.Open(<UpdateSubscription {...sub} />, {
                                            customClass: {
                                              container: 'modal-mobile-fullscreen',
                                              popup: 'max-w-400x',
                                            },
                                          })
                                        }
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        className=""
                                        key={`webhook-sub-${sub.id}-delete`}
                                        onClick={() =>
                                          Modal.Confirm(
                                            'Unsubscribe Webhook',
                                            'Are you sure you want to remove this webhook subscription?',
                                          ).then((value) => {
                                            if (value === true) {
                                              Modal.Open(
                                                <Security2faDialog
                                                  operation="WEBHOOK_SUBSCRIPTION_REMOVE"
                                                  handleSecurity={async (security, callback) => {
                                                    developerApi
                                                      .removeWebhookSubscription(sub.id, security)
                                                      .then(() => {
                                                        Modal.CloseAll();
                                                        dispatch(developerAction.removeWebhookSubscription(sub.id));
                                                      })
                                                      .catch((err) => {
                                                        callback(null, err);
                                                        handleRequestErrors(err);
                                                      });
                                                  }}
                                                />,
                                                {
                                                  allowOutsideClick: false,
                                                  allowEscapeKey: false,
                                                  customClass: {
                                                    container: 'modal-mobile-fullscreen',
                                                    popup: 'max-w-350x',
                                                  },
                                                },
                                              );
                                            }
                                          })
                                        }
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  }
                                  alignPoint
                                >
                                  <a style={{ display: 'inline-block', width: 30, height: 30, cursor: 'pointer', textAlign: 'center' }}>
                                    <BsThreeDotsVertical />
                                  </a>
                                </RcDropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <ConditionalElement
                        condition={developer.subscriptions.length === 0 && !isLoading}
                        element={
                          <div className="no-result">
                            <h6>You don't have any subscriptions</h6>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
