import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import serializeForm from 'form-serialize';
import { ConditionalElement } from '../ConditionalElement';
import { formatCurrency, handleRequestErrors } from '../../../utils';
import { CurrencyDefinition, WalletAsset, WalletTransferRecipient } from '../../../typings';
import { Button } from '../html/Button';
import { baseStore } from '../../../store';
import * as walletApi from '../../../api/wallets';
import { Modal } from '../Modal';
import { setWalletAssets } from '../../../actions/wallets';
import { Security2faDialog } from '../2FADialog';
import Gap from '../Gap';
import './styles.scss';
import { Input } from '../html/Input';
import { Note } from '../Note';
import { PhoneCodeSelect } from '../html/PhoneCodeSelect';

interface TransferDialogProps {
  currency?: CurrencyDefinition;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TransferDialog = (props: TransferDialogProps) => {
  const { wallets } = baseStore.getState();
  const [preview, setPreview] = useState(false);
  const [sourceCurrency, setSourceCurrency] = useState<{ label: string; value: string }>();
  const [sourceQuantity, setSourceQuantity] = useState<number>(0);
  const [sourceWallet, setSourceWallet] = useState<WalletAsset>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [recipientType, setRecipientType] = useState<'ALIAS' | 'EMAIL' | 'PHONE'>('EMAIL');
  const [recipient, setRecipient] = useState<string>('');
  const [memo, setMemo] = useState<string>();

  const sourceCurrencyOptions = wallets?.map(({ currency }) => ({ label: currency.name, value: currency.code, type: currency.type }));

  useEffect(() => {
    if (!props.currency) {
      return;
    }

    setSourceCurrency(sourceCurrencyOptions?.find((option) => option.value === props?.currency?.code));
    setSourceWallet(wallets?.find((wallet) => wallet.currency.code === props.currency?.code));
  }, [props.currency]);

  const executeTransfer = async () =>
    Modal.Open(
      <Security2faDialog
        operation="ASSET_TRANSFER"
        handleSecurity={async (security, callback) => {
          const target: WalletTransferRecipient = {
            currency: sourceWallet?.currency.code!,
          };

          switch (recipientType) {
            case 'ALIAS':
              target.userName = recipient;
              break;
            case 'EMAIL':
              target.email = recipient;
              break;
            case 'PHONE':
              target.phoneNumber = recipient;
              break;
          }

          walletApi
            .transfer({
              source: {
                assetId: sourceWallet?.id,
                currency: sourceWallet?.currency.code!,
              },
              target,
              amount: {
                value: sourceQuantity,
                currency: sourceWallet?.currency.code!,
              },
              memo,
              security,
            })
            .then(() => {
              Modal.Alert(
                'Transfer Success',
                `Your request to transfer ${formatCurrency(
                  sourceWallet?.currency.type!,
                  sourceQuantity,
                  sourceWallet?.currency.code!,
                  sourceWallet?.currency.decimals,
                )} to ${recipient} was completed successfully.`,
              );
              setTimeout(() => walletApi.getWallets().then((res) => baseStore.dispatch(setWalletAssets(res.assets))), 1500);
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );

  const sourceQuantityChange = (value: number) => {
    setSourceQuantity(value);
    setErrorMessage('');

    if (sourceWallet?.balance! < value) {
      setErrorMessage('You do not have sufficient balance for this transaction.');
    }
  };

  const transferDisabled = !sourceWallet || sourceQuantity === 0 || !!errorMessage;

  return preview ? (
    <>
      <div className="head">
        <h4>Transfer {sourceWallet?.currency.code}</h4>
      </div>

      <Gap v={1} />

      <div className="asset-transfer-preview">
        <div className="info">
          <div className="left">Recipient</div>
          <div className="right">{recipient}</div>
        </div>

        {memo ? (
          <div className="info">
            <div className="left">Note</div>
            <div className="right">{memo}</div>
          </div>
        ) : null}

        <div className="info">
          <div className="left">You Send</div>
          <div className="right">
            {formatCurrency(sourceWallet?.type || 'CRYPTO', sourceQuantity, sourceCurrency?.value!, sourceWallet?.currency.decimals)}
          </div>
        </div>

        <div className="info">
          <div className="left">They Receive</div>
          <div className="right">
            {formatCurrency(sourceWallet?.currency.type!, sourceQuantity, sourceCurrency?.value!, sourceWallet?.currency.orderDecimals)}
          </div>
        </div>

        <div className="info">
          <div className="left">Fee</div>
          <div className="right">
            {formatCurrency(sourceWallet?.type || 'CRYPTO', 0, sourceCurrency?.value!, sourceWallet?.currency.decimals)}
          </div>
        </div>

        <div className="info">
          <div className="left">Operation Time</div>
          <div className="right">Instant</div>
        </div>
      </div>

      <Gap v={1} />

      <Button
        disabled={transferDisabled}
        className="btn btn-primary full-width"
        text={`Transfer ${sourceCurrency?.value}`}
        onClick={() => executeTransfer()}
      />
    </>
  ) : (
    <>
      <div className="head">
        <h4>Transfer {sourceWallet?.currency.code}</h4>
      </div>

      <Note>
        <div>
          Transfer {sourceWallet?.currency.code || 'assets'} to another account via their <b>Phone Number</b>, <b>Email</b> or{' '}
          <b>Username</b> free of charge.
        </div>
      </Note>

      <Gap v={1} />

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          const { alias, email, phoneCode, phone, note } = serializeForm(ev.target as any, { hash: true });

          setMemo(note);

          switch (recipientType) {
            case 'ALIAS':
              setRecipient(alias);
              break;
            case 'EMAIL':
              setRecipient(email);
              break;
            case 'PHONE':
              setRecipient(phoneCode + phone.replace(/^0+/, '').replace(/\s/g, ''));
              break;
          }

          setPreview(true);
        }}
      >
        <div className="row">
          <div className="col-12">
            <label className="mb-2">
              Transfer <span>*</span>
            </label>
          </div>

          <div className="col-5">
            <Select
              name="sourceCurrency"
              options={[
                {
                  label: 'Fiat',
                  options: (sourceCurrencyOptions || []).filter((option) => option.type === 'FIAT'),
                },
                {
                  label: 'Crypto',
                  options: (sourceCurrencyOptions || []).filter((option) => option.type === 'CRYPTO'),
                },
              ]}
              onChange={(ev: any) => {
                setSourceCurrency(ev);
                setSourceWallet(wallets?.find((wallet) => wallet.currency.code === ev.value));
              }}
              value={sourceCurrency}
              classNamePrefix="custom-select"
            />
          </div>

          <div className="col-7 ps-0">
            <Cleave
              required
              id="input-text"
              name="sourceValue"
              className="form-control"
              placeholder={`${formatCurrency(
                sourceWallet?.currency.type!,
                0,
                sourceWallet?.currency.code!,
                sourceWallet?.currency.decimals,
              )}`}
              autoComplete="off"
              options={{
                numeral: true,
                numeralDecimalScale: sourceWallet?.currency.decimals,
                stripLeadingZeroes: false,
                numeralThousandsGroupStyle: 'thousand',
              }}
              value={sourceQuantity}
              onChange={(ev) => sourceQuantityChange(Number(ev.target.rawValue || 0))}
            />
          </div>
        </div>

        <ConditionalElement
          condition={!!sourceCurrency}
          element={
            <small className="text-muted">
              Available:{' '}
              {formatCurrency(
                sourceWallet?.type || 'CRYPTO',
                sourceWallet?.balance || 0,
                sourceCurrency?.value!,
                sourceWallet?.currency.decimals,
              )}{' '}
            </small>
          }
        />

        <Gap v={1} />

        <div className="row">
          <div className="col-12">
            <label className="mb-2">Note</label>
          </div>

          <div className="col-12">
            <Input id="input-note" name="note" className="form-control" type="text" placeholder="Ex. A gift" autoComplete="off" />
          </div>
        </div>

        <Gap v={1} />

        <div className="row">
          <div className="col-12">
            <label className="mb-2">
              To <span>*</span>
            </label>
          </div>

          <div className="col-12">
            <Select
              defaultValue={{ label: 'Email Address', value: 'EMAIL' }}
              name="transferType"
              options={[
                {
                  label: 'Email Address',
                  value: 'EMAIL',
                },
                {
                  label: 'Alias / Username',
                  value: 'ALIAS',
                },
                {
                  label: 'Phone Number',
                  value: 'PHONE',
                },
              ]}
              onChange={(ev: any) => setRecipientType(ev.value)}
              classNamePrefix="custom-select"
            />
          </div>

          <div className="col-12 mt-4">
            {recipientType === 'ALIAS' ? (
              <Input
                required
                id="input-recipient"
                name="alias"
                className="form-control"
                type="text"
                placeholder="Ex. johndoe"
                min={3}
                max={20}
                autoComplete="off"
              />
            ) : null}

            {recipientType === 'EMAIL' ? (
              <Input
                required
                id="input-recipient"
                name="email"
                className="form-control"
                type="email"
                placeholder="Ex. john@example.com"
                autoComplete="off"
              />
            ) : null}

            {recipientType === 'PHONE' ? (
              <div className="row">
                <div className="col-4">
                  <PhoneCodeSelect name="phoneCode" />
                </div>

                <div className="col-8 ps-0">
                  <div className="form-group">
                    <Cleave
                      required
                      name="phone"
                      autoComplete="off"
                      id="input-pri-phone"
                      className="form-control"
                      placeholder="Ex. 8051235678"
                      options={{
                        phone: true,
                        phoneRegionCode: 'any',
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <ConditionalElement condition={!!errorMessage} element={<div className="color-fd0000 text-center mt-3">{errorMessage}</div>} />

        <Gap v={2} />

        <Button type="submit" disabled={transferDisabled} className="btn btn-primary full-width" text="Preview Transfer" />

        <div className="text-center mt-2">All internal transfers are free</div>
      </form>
    </>
  );
};
